import React from "react";
import ToolkitProvider, { CSVExport }  from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Utils } from "../Utilis";
export class DynamicBootstrapTable extends React.Component<any, any> {
    constructor(props: any) {
    super(props);
     
    this.state = {
      
    };
  }


  render() {
    const { ExportCSVButton } = CSVExport;
    const customTotal = (from, to, size) => (
      <span className="text-muted">
        Showing {from} to {to} of {size} entries
      </span>
    );
    const options = {

      showTotal: true,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      },
      {
        text: '25', value: 25
      },
      {
        text: '50', value: 50
      },

      {
        text: 'All', value: this.props.data.length
      }] // A numeric array is also available. the purpose of above example is custom the text
    };
    // const columns = Object.keys(this.props.data[0]).map((key) => ({
    //     dataField: key,
    //     text: key.replace(/([A-Z])/g, ' $1').toUpperCase(),
    //     sort: true,
    //     headerStyle: { textAlign: 'center' },
    //     formatter: (cell: any, row: any) => (
    //       <EllipsisWithTooltip placement="bottom">
    //         {row[key] || ""}
    //       </EllipsisWithTooltip>
    //     ),
    //   }));

    const columns = Object.keys(this.props.data[0]).map((key) => {
      const isCurrencyField = ['total amount', 'discount', 'total', 'unit price','order amount','order total','invoice total','sale tax amount'].includes(key.toLowerCase());
    
      return {
        dataField: key,
        text: key.replace(/([A-Z])/g, ' $1').toUpperCase(),
        sort: true,
        headerStyle: { textAlign: 'center' },
        classes: isCurrencyField ? 'text-end pe-4 divgreen' : 'text-start',
        formatter: (cell: any, row: any) => (
          <EllipsisWithTooltip placement="bottom">
            {isCurrencyField ? Utils.currencyFormat(row[key]) : row[key] || ""}
          </EllipsisWithTooltip>
        ),
      };
    });
    

    return (
        <ToolkitProvider
          keyField="id"
          data={this.props.data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <section className="pb-3">
                   
              <div className="page-heading  d-flex  ">
          <div className="action-group d-flex flex-row ml-auto">
              
          <ExportCSVButton {...props.csvProps}>
            Export to CSV / Excel
          </ExportCSVButton>
            </div>
</div>
                <div className="table-responsive divbottom">
                  <div className="ml-auto transactions-list">
               
          {/* <button className="btn btn-primary m-2" onClick={exportPDF}>
            Export to PDF
          </button>
          <button className="btn btn-warning m-2" onClick={exportExcel}>
            Export to Excel
          </button> */}
                    <BootstrapTable {...props.baseProps} 
                    pagination={paginationFactory(options)}
                    exportCSV
                    />
                  </div>
                </div>
              </section>
            </div>
          )}
        </ToolkitProvider>
      );
  }


 
}
