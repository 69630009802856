import React, { ChangeEvent } from "react";
import { Modal, Container, Button, Form, Spinner, Nav } from "react-bootstrap";
import { SingleDropdownListWithoutSearchBoot } from "../Common/Components/SingleDropdownListWithoutSearchBoot";
import FancyDropdown from "../Common/Components/FancyDropdown";

import ReportViewer from "./Reports/ReportViewer";
import DatePicker from "react-datepicker";
import { CustomerService } from "../Common/Services/CustomerService";
import moment from "moment-timezone";
import { Utils } from "../Common/Utilis";
import { resolve, reject } from "q";
import { ScheduledReportSlideout } from "./Reports/ScheduleReportSlideout";
import { Typeahead } from "react-bootstrap-typeahead";
import { toast, ToastContainer } from "react-toastify";
import Tab from 'react-bootstrap/Tab';
import { ReportsService } from "../Common/Services/ReportsService";
import { VendorSearch } from "../Common/Components/VendorSearch";
import { VendorService } from "../Common/Services/VendorService";
import { DropdownMultiSelect } from "../Common/Components/DropdownMultiSelect";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { DynamicBootstrapTable } from "../Common/Components/DynamicBootstrapTable";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { debug } from "console";
import  DateRangeSelector  from "../Common/Components/DateRangeSelector";
let storage = JSON.parse(localStorage.getItem("storage")!);
let currentUtcTime =
  storage === null ? "America/Chicago" : (storage.currentUtcTime as any);
let tenantID = storage === null ? 0 : (storage.tenantID as any);

export class SearchAll extends React.Component<any, any> {
  private secondDropdownRef: React.RefObject<any>;
  private thirdDropdownRef: React.RefObject<any>;


  constructor(props: any) {
    super(props);
    this.secondDropdownRef = React.createRef();
    this.thirdDropdownRef = React.createRef();

    const currentDate = new Date();
    const formattedCurrentDate = this.formatDateToLocal(currentDate);
    const firstMonthDate = new Date(currentDate.getFullYear(), 0, currentDate.getDate());
    const formattedFirstMonthDate = this.formatDateToLocal(firstMonthDate);
    this.secondDropdownRef = React.createRef();


    this.state = {
      isSecondDropdownOpen:false,
      isThirdDropdownOpen:false,
      urlParameter: "",
      reportId: this.props.reportId,
      reportName: this.props.ReportName,
      reportGroup: this.props.reportGroup,
      id: this.props.id,
      iframeUrl: "",
      ispopupshow: false,
      MyReportName: "",
      isOpen: false,
      selectedModalOption: "",
      selectedcolumnType: "",
      showPopup: false,
      filter: [],
      days: null,
      formData: {
        selectedcolumn: "All",
        reportselected: this.props.ReportName,
        reportselectedid: this.props.reportId,
        filterValue: "",
        searchStartDate: formattedFirstMonthDate,
        searchEndDate: formattedCurrentDate,
        startDate: null,
        endDate: null,
        excludes: [],
        lastDays: ""
      },
      firstselectedcolumn: "",
      secondselectedcolumn: "",
      modaloptions: ["Contains", "Exact Match", "Equals", "Greater than", "Less than"],
      orders: [],
      ExcludOptions: [
        { value: "Customer Name", label: "Customer Name" },
        { value: "Customer Code", label: "Customer Code" },
        { value: "Customer PO No.", label: "Customer PO No." },
        { value: "Order No.", label: "Order No." },
        { value: "Part No.", label: "Part No." },
        { value: "Part Description", label: "Part Description" },
      ],
      data: [],
      options: [
        "Part No.",
        "Part description",
        "Qty",
        "Unit price",
        "Total price",
        "Job No.",
        "Customer code",
      ],
      conditions: [
        { label: "and", value: "and" },
        { label: "or", value: "or" },
      ],
      filterOptions: [],
      selectOptions: [],
      firstSelectedId: null,
      secondSelectedId: null,
      thirdSelectedId: null,
      firstDropdownOptions: [],
      secondDropdownOptions: [],
      thirdDropdownOptions: []
    };

  }

  formatDateToLocal = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleDelete = (itemToDelete) => {
    this.setState((prevState) => ({
      filter: prevState.filter.filter(
        (item) =>
          item.fieldName !== itemToDelete.fieldName ||
          item.fieldValue !== itemToDelete.fieldValue ||
          item.fieldRange !== itemToDelete.fieldRange
      ),
    }));
  };

  componentDidMount() {

    this.GetReportFilterSetting();
    this.loadFirstDropdown();
    // debugger;

  }

  loadFirstDropdown = () => {
    const request = { typeid: 1, typeid3: 0 };
    this.GetFilterDropdown(request, 'firstDropdownOptions');
  };

  loadSecondDropdown = (firstId) => {
    const request = { typeid: firstId, typeid3: firstId };
    this.GetFilterDropdown(request, 'secondDropdownOptions');
  };

  loadThirdDropdown = (thirdId) => {
    // debugger;
    const request = { typeid: thirdId, typeid3: thirdId };
    this.GetFilterDropdown(request, 'thirdDropdownOptions');
  };


  GetFilterDropdown = (request, stateKey) => {


    ReportsService.GetFilterDropdown(request)
      .then((result) => {
        if (result !== null) {
          // debugger;
          let selectOptions = result
            .map((item: any) => ({
              id: item.id,
              name: item.filterName,
              type: item.datattype
            }));

          if (stateKey == "firstDropdownOptions") {
            this.setState({ firstDropdownOptions: selectOptions});
          } else if (stateKey == "secondDropdownOptions") {
            this.setState({ secondDropdownOptions: [] }
              , () => {
                this.setState({ secondDropdownOptions: selectOptions ,isSecondDropdownOpen : true});
                if (this.secondDropdownRef.current) {
                  this.secondDropdownRef.current.click();
                }
              }
            );
          } else {
            this.setState({ thirdDropdownOptions: [] }
              , () => {
                this.setState({ thirdDropdownOptions: selectOptions,isThirdDropdownOpen : true });
                if (this.thirdDropdownRef.current) {
                  this.thirdDropdownRef.current.click();
                }  
              }
            );

          }

        }
      })
      .catch((error) => {
        this.setState({ isSpinner: false });
      });
  };

  handleDaysChange = (e) => {
    const days = e.target.value;
    if (!days) {
      this.setState({
        days: "",
        formData: {
          ...this.state.formData,
          startDate: null,
          endDate: null,
        },
      });
    } else {
      this.setState({ days }, () => {
        if (!isNaN(days)) {
          const endDate = new Date();
          const startDate = new Date();
          startDate.setDate(endDate.getDate() - parseInt(days, 10));
          const formatDate = (date) => {
            return date.toISOString().split('T')[0];
          };

          this.setState({
            formData: {
              ...this.state.formData,
              startDate: formatDate(startDate),
              endDate: formatDate(endDate),
            },
          });
        }
      });
    }
  };

  handleSelectedItems = (dropdownType, selectedId) => {
    // debugger;
    if (dropdownType === 'first') {
      this.setState({ firstSelectedId: selectedId }, () => {
        this.loadSecondDropdown(selectedId);
      });
    } else if (dropdownType === 'second') {
      this.setState({ secondSelectedId: selectedId }, () => {
        this.loadThirdDropdown(selectedId);    
      });
    } else {
      this.setState({isThirdDropdownOpen : false });
      const { formData, thirdDropdownOptions } = this.state;
      const selectedColumn = this.getSelectedOptionName(selectedId, this.state.thirdDropdownOptions);
      const updatedFormData = { ...formData, selectedcolumn: selectedColumn };

      let filterOptions = [];
      if (selectedColumn === "Customer Name") {
        this.GetCustomer();
      } else if (selectedColumn === "Vendor Name") {
        this.GetVendor();
      }

      const matchedOption = thirdDropdownOptions.find(option => option.id == selectedId);
      const selectedcolumnType = matchedOption ? matchedOption.type : null;
      console.log("pppp", selectedcolumnType, selectedId);
      this.setState({
        formData: updatedFormData,
        filterOptions,
        selectedcolumnType,
        showPopup: true
      });

      this.setState({ thirdSelectedId: selectedId });
    }
  };

  getSelectedOptionName = (selectedId, options) => {
    const selectedOption = options.find((option) => option.id === selectedId);
    return selectedOption ? selectedOption.name : "Select";
  };

  GetReportFilterSetting = () => {

    ReportsService.GetReportFilterSetting(1)
      .then(async (result: any | null) => {
        if (result !== null) {
          console.log("ajajaj", result);

          let selectOptions = result
            .map((item) => ({
              id: item.columnDisplayName,
              name: item.columnDisplayName,
              type: item.columnType
            }));
          this.setState({ selectOptions });
        }
        resolve();

      })
      .catch((error) => {
        this.setState({ isSpinner: false });
        reject();
      });

  };





  handleClose = () => {
    this.props.closeSlideout();
    this.setState({ iframeUrl: "" });
  };




  // handleApply = () => {
  //   this.setState({showPopup:false, selectedOne:`${this.state.formData.selectedcolumn}-${this.state.formData.filterValue}`});
  // };

  handleApply = () => {
    this.setState((prevState) => {
      debugger;


      const fieldRange= this.state.selectedcolumnType === "Date"
      ? "Range"
      : prevState.selectedModalOption;

      const fieldValue =
        this.state.selectedcolumnType === "Date"
          ? ` '${prevState.formData.startDate}' and '${prevState.formData.endDate}'`
          : prevState.formData.filterValue;

      return {
        showPopup: false,
        filter: [
          ...prevState.filter,
          {
            fieldName: prevState.formData.selectedcolumn,

            fieldValue: fieldValue,
            fieldRange:fieldRange,
          }
        ],
        formData: {
          ...prevState.formData,
          selectedcolumn: "All",
          filterValue: ""
        },
        selectedModalOption: ""
      };
    });
  };




  updateMultiSelectItem = (selectedItem: any) => {
    const { formData } = this.state;

    const selectedValues = selectedItem.map((item: any) => item.value);
    formData.excludes = selectedValues;
    this.setState({ formData, isStateChanged: true });

  };


  handleOptionSelect = (option) => {
    this.setState({ selectedModalOption: option });
  };

  handleSelectedItem = (control: any, id: any) => {
    console.log("controll", control, id);
    if (this != undefined && id.length > 0) {
      if (control === "third") {

        const { formData, thirdDropdownOptions } = this.state;
        const updatedFormData = { ...formData, selectedcolumn: id };

        let filterOptions = [];
        if (id === "Customer Name") {
          this.GetCustomer();
        } else if (id === "Vendor Name") {
          this.GetVendor();
        }

        const matchedOption = thirdDropdownOptions.find(option => option.name === id);
        const selectedcolumnType = matchedOption ? matchedOption.type : null;
        console.log("pppp", formData, selectedcolumnType);
        this.setState({
          formData: updatedFormData,
          filterOptions,
          selectedcolumnType,
          showPopup: true
        });
      }

      if (control == "first") {
        this.setState({ firstselectedcolumn: id });


      }

      if (control == "second") {
        this.setState({ secondselectedcolumn: id });


      }



    }
  };

  handleInputChange = (field) => (event) => {
    const value = event.target ? event.target.value : event;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [field]: value,
      },
    }));
  };

  GetCustomer = () => {
    let requestlist: any = {};
    CustomerService.GetCustomerlist(requestlist)
      .then(async (result: any | null) => {
        if (result !== null) {
          console.log("ress", result);
          let selectOptions = result
            .filter((item) => item.status === "Active")
            .map((item) => ({
              id: item.customer_id,
              name: item.company_name,
            }));
          this.setState({ filterOptions: selectOptions });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  GetVendor = () => {
    let requestlist: any = {};
    VendorService.GetVendorlist(requestlist)
      .then(async (result: any | null) => {
        if (result !== null) {
          console.log("vendor", result);
          let selectOptions = result
            .filter((item) => item.status === "Active")
            .map((item) => ({
              id: item.vendor_id,
              name: item.company_name,
            }));
          this.setState({ filterOptions: selectOptions });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  handleChange = (range) => {
    this.setState({
      formData: {
        ...this.state.formData,
        startDate: range[0],
        endDate: range[1],
      },
    });
  };



  handleSearch = () => {
    let request: any = {};

    this.setState({isSpinner:true});
    const cdate = new Date(
      Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime)
    );
    const firstDayOfMonth = new Date(cdate.getFullYear(), cdate.getMonth(), 1);

    const datefrom =
      this.state.formData.searchStartDate === null ||
        this.state.formData.searchStartDate === undefined
        ? firstDayOfMonth
        : this.state.formData.searchStartDate === ""
          ? firstDayOfMonth
          : new Date(this.state.formData.searchStartDate);

    const dateto =
      this.state.formData.searchEndDate === null ||
        this.state.formData.searchEndDate === undefined
        ? new Date(Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime))
        : this.state.formData.searchEndDate === ""
          ? new Date(Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime))
          : new Date(this.state.formData.searchEndDate);

    if (datefrom > dateto) {
      toast.error("Invalid date range.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    let formData = { ...this.state.formData };
 

    formData.Searchid = this.state.firstSelectedId;
    formData.Searchid1 = this.state.secondSelectedId;
    formData.dateFrom=datefrom;
    formData.dateTo=dateto;
    let options: any = [];
    let item: any = {};

    item = {};
    item.id = 0;
    item.Reportparamid = this.props.id;
    item.ControlName = "datefrom";
    item.ControlType = "between";
    item.ControlSection = "filter";
    item.ControlValue = datefrom;
    options.push(item);

    item = {};
    item.id = 0;
    item.Reportparamid = this.props.id;
    item.ControlName = "dateto";
    item.ControlType = "between";
    item.ControlSection = "filter";
    item.ControlValue = dateto;
    options.push(item);
    this.state.filter.forEach(element => {
      debugger;
   

      item = {};
      item.id = 0;
      item.Reportparamid = this.props.id;
      item.ControlName = element.fieldName;
      item.ControlType = element.fieldRange;
      item.ControlSection = "filter";
      item.ControlValue = element.fieldValue;
      options.push(item);
    });
    formData.SearchLogDetails = options;
    request = Object.assign({}, JSON.parse(JSON.stringify(formData)));

    // const request1 = {reportid:1,from:datefrom,to:dateto};
    ReportsService.SeachAllData(request)
      .then(async (result: any | null) => {
        debugger;
        if (result !== null) {


          this.setState({ data: [] },  () => {

            this.setState({data:result,isSpinner:false});
          }


          );
        

        }
        else
        {
          this.setState({data:[],isSpinner:false});
          toast.error("Data not found!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "OrderSlideout",
          });
         
        }
        resolve();
      })
      .catch((error) => {
        toast.error(error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "OrderSlideout",
        });
        this.setState({ isSpinner: false });
        reject();
      });


  };


  handleExcludeChange = (option) => {
    const { selectedExcludes } = this.state;
    this.setState({
      selectedExcludes: selectedExcludes.includes(option)
        ? selectedExcludes.filter((item) => item !== option)
        : [...selectedExcludes, option],
    });
  };

  MyCancel = () => {
    this.setState({ ispopupshow: false });
  };


  handleChangeBuyer = (event: any) => {
    console.log("event", event);
    let formData = { ...this.state.formData };
    if (event.length > 0) {
      formData.filterValue = event[0].name;
    }
    this.setState({ formData: formData });
  };



  render() {
    const handleSelect = (value: string) => {
      console.log("Selected value:", value);
    };
    console.log(this.props);
    const { iframeUrl } = this.state;
    // this.getmyReportDetails();
    const cdate = new Date(
      Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime)
    );
    const firstDayOfMonth = new Date(cdate.getFullYear(), cdate.getMonth(), 1);

    const customTotal = (from, to, size) => (
      <span className="text-muted">
        Showing {from} to {to} of {size} entries
      </span>
    );
    const options = {
      showTotal: true,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "50",
          value: 50,
        },

        {
          text: "All",
          // value: this.state.searcResult.length
        },
      ], // A numeric array is also available. the purpose of above example is custom the text
    };



    const retrunType = (name: string): string => {

      let retrunValue: any;
      if (name == "Exact Match" || name == "Equals") {
        retrunValue = "=";

      }
      else if(name=="Range")
      {
        retrunValue = "Between";
      }
      else if (name == "Contains") {
        retrunValue = "like %";
      }
      else if (name == "Greater than") {
        retrunValue = ">";
      }
      else if (name == "Less than") {
        retrunValue = "<";
      }
      else {

      }

      return `${retrunValue}`;
    };

    return (

      <React.Fragment>
        <Container fluid className="body-sec">
          <div className="page-heading  d-flex  ">
            Search All
            <div className="action-group d-flex flex-row ml-auto">
              
              <Button
                onClick={this.handleSearch}
              >Search</Button>
            </div>
          </div>
          <section className="px-0">
            <div className="row">
              <div className="col-12 p-0 d-flex flex-column">
                <div className="row" style={{ borderTop: "1px solid #e0e3e6", borderBottom: "1px solid #e0e3e6", width: "99%", margin: "0" }}>
                  <div className="col-2 p-1">
                    <Form.Group
                      controlId="customercode"
                      style={{ marginTop: "2px" }}
                    >
                      <div>
                        <SingleDropdownListWithoutSearchBoot
                          itemList={this.state.firstDropdownOptions}
                          handleSelectedItem={(id) => this.handleSelectedItems('first', id)}
                          defaultText={"Select"}
                          defaultName={this.getSelectedOptionName(this.state.firstSelectedId, this.state.firstDropdownOptions)}
                          controlID="1"
                          id={"ddlselected"}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="col-2 p-1">
                    <Form.Group
                      controlId="customercode"
                      style={{ marginTop: "2px" }}
                    >

                      <SingleDropdownListWithoutSearchBoot
                        dropdownToggleRef={this.secondDropdownRef}
                        itemList={this.state.secondDropdownOptions}
                        handleSelectedItem={(id) => this.handleSelectedItems('second', id)}
                        // defaultItem={this.state.secondselectedcolumn}
                        defaultText={"Select"}
                        defaultName={this.getSelectedOptionName(this.state.secondSelectedId, this.state.secondDropdownOptions)}
                        controlID="1"
                        id={"ddlselected"}
                       // show={this.state.isSecondDropdownOpen}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-2 p-1">
                    <Form.Group
                      controlId="customercode"
                      style={{ marginTop: "2px" }}
                    >

                      <SingleDropdownListWithoutSearchBoot
                        dropdownToggleRef={this.thirdDropdownRef}
                        itemList={this.state.thirdDropdownOptions}
                        // handleSelectedItem={this.handleSelectedItem.bind(
                        //   this,
                        //   "third"
                        // )}
                        handleSelectedItem={(id) => this.handleSelectedItems('third', id)}

                        // defaultItem={this.state.formData.selectedcolumn}
                        defaultName={this.getSelectedOptionName(this.state.thirdSelectedId, this.state.thirdDropdownOptions)}
                        // defaultName={this.state.formData?.selectedcolumn || "Select Filter"}
                        controlID="1"
                        id={"ddlselected"}
                       // show={this.state.isThirdDropdownOpen}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-6">
                  <DateRangeSelector ></DateRangeSelector>
                  </div>
                  {/* <div className="col-3 p-1">
              
              
                    <Form.Group controlId="date" style={{ marginTop: "2px" }}>
                      <div className="d-flex align-items-center">
                        <Form.Label className="font-semibold mb-0 me-2">From</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Start Date"
                          value={this.state.formData.searchStartDate || ""}
                          onChange={this.handleInputChange("searchStartDate")}
                        />
                      </div>
                    </Form.Group>
                  </div>


                  <div className="col-3 p-1">
                    <Form.Group controlId="customercode" style={{ marginTop: "2px" }}>
                      <div className="d-flex align-items-center">
                        <Form.Label className="font-semibold mb-0 me-2">To</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="End Date"
                          value={this.state.formData.searchEndDate || ""}
                          onChange={this.handleInputChange("searchEndDate")}
                        />
                      </div>
                    </Form.Group>
                  </div> */}




                </div>

                <div className="d-flex justify-content-between mt-auto">
                  {/* Tags section - Left */}
                  <div style={{ flex: 7, marginRight: '1%' }}>
                    {/* {this.state.filter.length > 0 && this.state.filter.map((item, index) => ( 
      <>
              {index!=0 && (
<div
key={index}
className="p-2 my-3 position-relative"
style={{
  borderRadius: '5px',
  fontSize: '14px',
  display: 'inline-block',
}}
>
<div>
<FancyDropdown options={this.state.conditions} onSelect={handleSelect}   />
</div>

</div>
)
  }
      <div
        key={index}
        className="p-2 my-3 position-relative"
        style={{
          background: '#BCC0C3',
          borderRadius: '5px',
          fontSize: '14px',
          display: 'inline-block',
          marginRight: '8px'
        }}
      >

        {`${item.fieldName} ${retrunType(item.fieldRange)} ${item.fieldValue}`} 
        <button
          onClick={() => this.setState((prevState) => ({
            filter: prevState.filter.filter((_, i) => i !== index)
          }))}
          style={{
            position: 'absolute',
            top: '-20px',
            right: '-15px',
            background: 'transparent',
            border: 'none',
            color: '#BCC0C3',
            fontSize: '16px',
            cursor: 'pointer'
          }}
          aria-label="Close"
        >
          &times;
        </button>
      </div>

</>

    ))} */}
                    {this.state.filter.length > 0 && (() => {
                      const groupedItems = this.state.filter.reduce((acc, item) => {
                        acc[item.fieldName] = acc[item.fieldName] || [];
                        acc[item.fieldName].push(item);
                        return acc;
                      }, {} as Record<string, typeof this.state.filter>);

                      let output: JSX.Element[] = [];
                      Object.keys(groupedItems).forEach((fieldName, groupIndex) => {
                        const items = groupedItems[fieldName];
                        const isGroup = items.length > 1;

    if (isGroup) {
      output.push(
        <span key={`open-${groupIndex}`} style={{ fontWeight: 'bold' }}>( </span>
      );
    }

    items.forEach((item, index) => {
      output.push(
        <React.Fragment key={`${fieldName}-${index}`}>
          {/* {index > 0 && (
            <div
              className="p-2 my-3 position-relative"
              style={{ display: 'inline-block'}}
            >
              <FancyDropdown options={this.state.conditions} onSelect={handleSelect} />
            </div>
          )} */}
          <div
            className="p-2 my-3 position-relative"
            style={{
              background: '#BCC0C3',
              borderRadius: '5px',
              fontSize: '14px',
              display: 'inline-block',
              marginRight: '8px'
            }}
          >
            {`${item.fieldName} ${retrunType(item.fieldRange)} ${item.fieldValue}`}
            <button
          onClick={() => this.handleDelete(item)}
          style={{
            position: 'absolute',
            top: '-20px',
            right: '-15px',
            background: 'transparent',
            border: 'none',
            color: '#BCC0C3',
            fontSize: '16px',
            cursor: 'pointer'
          }}
          aria-label="Close"
        >
          &times;
        </button>
          </div>
        </React.Fragment>
      );
    });

                        // if (isGroup) {
                        //   output.push(
                        //     <span key={`close-${groupIndex}`} style={{ fontWeight: 'bold' }}>)</span>
                        //   );
                        // }

                        // if (groupIndex < Object.keys(groupedItems).length - 1) {
                        //   output.push(
                        //     <div
                        //       key={`operator-${groupIndex}`}
                        //       className="p-2 my-3 position-relative"
                        //       style={{ display: 'inline-block', marginRight: '8px' }}
                        //     >
                        //       <FancyDropdown options={this.state.conditions} onSelect={handleSelect} />
                        //     </div>
                        //   );
                        // }
                      });

                      return output;
                    })()}

                  </div>

                  {/* Filter Popup section - Right */}
                  <div style={{ flex: 3 }}>
                    {this.state.showPopup && (
                         <div className="react-confirm-alert-overlay confirm-modal undefined">
                        <div className="confirm-modal undefined">
                          <div className="react-confirm-alert">
                            <div className="react-confirm-alert-body p-5 py-3" style={{ width: "450px" }}>
                              <div>
                                <div className="font-semibold text-center my-3" style={{ fontSize: '20px', color: "black" }}>
                                  {this.state.formData.selectedcolumn}
                                </div>

                                <div className="d-flex flex-wrap justify-content-center">
                                  {this.state.selectedcolumnType === "Date" ? (
                                    <>
                                      <span className={`px-4 py-2 mx-1 my-1`} style={{ cursor: 'pointer', background: '#BCC0C3', color: '#000' }}>
                                        Date Range
                                      </span>
                                      <div className="d-flex align-items-center my-3">
                                        <span className={`px-4 py-2 mx-1`} style={{ cursor: 'pointer', background: '#BCC0C3', color: '#000' }}>
                                          Last
                                        </span>
                                        <Form.Control
                                          type="number"
                                          placeholder="Days"
                                          className="mx-2"
                                          value={this.state.days}
                                          onChange={this.handleDaysChange}
                                          style={{ width: "70px", textAlign: "center" }}
                                        />
                                        <span className={`px-4 py-2 mx-1`} style={{ cursor: 'pointer', background: '#BCC0C3', color: '#000' }}>
                                          Days
                                        </span>
                                      </div>
                                    </>
                                  ) : (
                                    this.state.modaloptions
                                      .filter((option) => {
                                        if (this.state.selectedcolumnType === "int") {
                                          return true;
                                        }
                                        if (this.state.selectedcolumnType === "varchar" || this.state.selectedcolumnType === "") {
                                          return !["Greater than", "Less than", "Equals"].includes(option);
                                        }
                                        return true;
                                      })
                                      .map((option, index) => (
                                        <span
                                          key={index}
                                          className={`px-4 py-2 mx-1 my-1`}
                                          onClick={() => this.handleOptionSelect(option)}
                                          style={{
                                            cursor: 'pointer',
                                            background: this.state.selectedModalOption === option ? '#164DA0' : '#BCC0C3',
                                            color: this.state.selectedModalOption === option ? '#fff' : '#000',
                                          }}
                                        >
                                          {option}
                                        </span>
                                      ))
                                  )}
                                </div>
                              </div>
                              <div className="d-flex justify-content-center">
                                <Form.Group controlId="emptyTextField" style={{ width: "54%" }}>
                                  {this.state.selectedcolumnType === "Date" ? (
                                    <div className="d-flex justify-content-center gap-2">
                                      <Form.Control
                                        type="date"
                                        placeholder="Start Date"
                                        className="mb-3 mt-4"
                                        value={this.state.formData.startDate || ""}
                                        onChange={this.handleInputChange("startDate")}
                                      />
                                      <Form.Control
                                        type="date"
                                        placeholder="End Date"
                                        className="mb-3 mt-4"
                                        value={this.state.formData.endDate || ""}
                                        onChange={this.handleInputChange("endDate")}
                                      />
                                    </div>
                                  ) : (
                                    this.state.formData.selectedcolumn === "Customer Name" || this.state.formData.selectedcolumn === "Vendor Name" ? (
                                      <Typeahead
                                        id="selections-example"
                                        labelKey="name"
                                        onInputChange={(text) => {
                                          this.state.formData.filterValue = text;
                                        }}
                                        onChange={(e) => this.handleChangeBuyer(e)}
                                        className="mb-3 mt-4 w-full"
                                        options={this.state.filterOptions}
                                        placeholder="Enter Value"
                                        selected={this.state.Defaultselected}
                                      />
                                    ) : (
                                      <div>
                                        <Form.Control
                                          type="text"
                                          placeholder="Enter value"
                                          className="mb-3 mt-4"
                                          value={this.state.formData.filterValue}
                                          onChange={this.handleInputChange("filterValue")}
                                        />
                                      </div>
                                    )
                                  )}
                                </Form.Group>
                              </div>
                              <br />
                              <div className="d-flex gap-3 align-items-center justify-content-end height-72">
                                <div className="d-flex gap-3 align-items-center">
                                  <Button
                                    variant="primary"
                                    type="button"
                                    className="space btn-discard"
                                    onClick={() => {
                                      this.setState({ showPopup: false });
                                    }}
                                  >
                                    Discard
                                  </Button>
                                  <Button
                                    id="btnSave"
                                    variant="primary"
                                    type="button"
                                    onClick={() => {
                                      this.handleApply();
                                    }}
                                  >
                                    Apply
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

              </div>
              <div className="col-12 pt-4" style={{ height: "70vh" }}>
                {/* {this.state.data.length > 0 && (

                       <ToolkitProvider
                    keyField="id"
                    data={this.state.data}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        
                        <section className="pb-3">
                          <div className="table-responsive divbottom">
                            <div className="ml-auto transactions-list">
                              <BootstrapTable
                                {...props.baseProps}
                                // rowEvents={this.rowEvents}
                                // pagination={paginationFactory(options)}
                              />
                            </div>
                          </div>
                        </section>
                      </div>
                    )}
                  </ToolkitProvider>
                )} */}

                {this.state.data.length > 0 && (
                  <DynamicBootstrapTable data={this.state.data} />
                )}
 {(this.state.isSpinner || this.state.isSaveSpinner) && (
              <div className="loader-spinner d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="success" />
              </div>
            )}
              </div>
            </div>
          </section>
        </Container>
      </React.Fragment>
    );
  }
}