import React, { useState,useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SingleDropdownListWithoutSearchBoot } from "./SingleDropdownListWithoutSearchBoot";
import { Form } from "react-bootstrap";
import moment from "moment";

const DateRangeSelector: React.FC = () => {
  const currentYear = new Date().getFullYear();
    
  // Create a new Date object for January 1st of the current year
  const firstJan = new Date(currentYear, 0, 1); // month is zero-indexed
  // Define startDate and endDate to allow Date or null types
  const [startDate, setStartDate] = useState<Date | null>(firstJan);
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [selectedOption, setSelectedOption] = useState<String | null>(
    "Date Range"
  );
  const options = [
    { id: "Last 7 Days", name: "Last 7 Days" },
    { id: "Last 30 Days", name: "Last 30 Days" },
    { id: "Last 60 Days", name: "Last 60 Days" },
    { id: "Last 90 Days", name: "Last 90 Days" },
    { id: "MTD", name: "MTD" },
    { id: "YTD", name: "YTD" },
    { id: "Date Range", name: "Date Range" },
  ];
 

  const handleSelectedItems = (selectedId: any) => {
    console.log("Selected----", selectedId);
    setSelectedOption(selectedId);
    if (selectedId === "Last 7 Days") {
      setStartDate(moment().subtract(6, "days").toDate());
      setEndDate(new Date());
    } else if (selectedId === "Last 30 Days") {
      setStartDate(moment().subtract(29, "days").toDate());
      setEndDate(new Date());
    } 
 else if (selectedId === "Last 60 Days") {
    setStartDate(moment().subtract(59, "days").toDate());
    setEndDate(new Date());
  } 
  else if (selectedId === "Last 90 Days") {
    setStartDate(moment().subtract(89, "days").toDate());
    setEndDate(new Date());
  }
  else if (selectedId === "MTD") {
    const today = new Date();
 const getFirstDayOfCurrentMonth= new Date(today.getFullYear(), today.getMonth(), 1);
  
   
    setStartDate(new Date(getFirstDayOfCurrentMonth ));
    setEndDate(new Date());
  }
  else if (selectedId === "YTD") {
    const currentYear = new Date().getFullYear();
    
    // Create a new Date object for January 1st of the current year
    const firstJan = new Date(currentYear, 0, 1); // month is zero-indexed
    setStartDate(firstJan);
    setEndDate(new Date());
  }
    else {
      setStartDate(moment().subtract(30, "days").toDate());
      setEndDate(new Date());
    }
  };


  const changeStartDate = (newStartDate: Date) => {
    setStartDate(newStartDate);

    if (!newStartDate || !selectedOption) return;
    
    let newEndDate = endDate;
    
    switch (selectedOption) {
      case "Last 7 Days":
        newEndDate = moment(newStartDate).add(6, "days").toDate();
        break;
      case "Last 30 Days":
        newEndDate = moment(newStartDate).add(29, "days").toDate();
        break;
      case "Last 60 Days":
        newEndDate = moment(newStartDate).add(59, "days").toDate();
        break;
      case "Last 90 Days":
        newEndDate = moment(newStartDate).add(89, "days").toDate();
        break;
      default:
        break;
    }

    if (newEndDate && !moment(endDate).isSame(moment(newEndDate))) {
      setEndDate(newEndDate);
    }

  };

  const changeEndDate = (newEndDate: Date) => {
    setEndDate(newEndDate);

    if (!newEndDate || !selectedOption) return;
    let newStartDate = startDate;
    
    switch (selectedOption) {
      case "Last 7 Days":
        newStartDate = moment(newEndDate).subtract(6, "days").toDate();
        break;
      case "Last 30 Days":
        newStartDate = moment(newEndDate).subtract(29, "days").toDate();
        break;
      case "Last 60 Days":
        newStartDate = moment(newEndDate).subtract(59, "days").toDate();
        break;
      case "Last 90 Days":
        newStartDate = moment(newEndDate).subtract(89, "days").toDate();
        break;
      default:
        break;
    }

    if (newStartDate && !moment(startDate).isSame(moment(newStartDate))) {
      setStartDate(newStartDate);
    }
  };

 
  return (
    <div className="col-12 d-flex flex-column">
    <div className="row">
    <div className="col-4 p-1">
        <Form.Group controlId="customercode" style={{ marginTop: "2px" }}>
          <div>
            <SingleDropdownListWithoutSearchBoot
              itemList={options}
              handleSelectedItem={(id) => handleSelectedItems(id)}
              defaultText={"Select"}
              defaultName={selectedOption}
              controlID="1"
              id={"ddlselected"}
            />
          </div>
        </Form.Group>
      </div>

      <div className="col-4 p-1">
        <Form.Group controlId="date" style={{ marginTop: "2px" }}>
          <div className="d-flex align-items-center">
            <Form.Label className="font-semibold mb-0 me-2">From</Form.Label>
            <DatePicker
              selected={startDate}
              onChange={changeStartDate}
              selectsStart
              className="form-control form-control-md"
              startDate={startDate}
              endDate={endDate}
              maxDate={new Date()}
              placeholderText="Select start date"
            />
          </div>
        </Form.Group>
      </div>

      <div className="col-4 p-1">
        <Form.Group controlId="date" style={{ marginTop: "2px" }}>
          <div className="d-flex align-items-center">
            <Form.Label className="font-semibold mb-0 me-2">To</Form.Label>
            <DatePicker
              selected={endDate}
              onChange={changeEndDate}
              selectsEnd
              startDate={startDate}
              className="form-control form-control-md"
              endDate={endDate}
              minDate={startDate}
              maxDate={new Date()}
              placeholderText="Select end date"
            />
          </div>
        </Form.Group>
      </div>

      {/* <div>
        <p>
          Selected Range: {startDate ? moment(startDate).format('YYYY-MM-DD') : ''} 
          to {endDate ? moment(endDate).format('YYYY-MM-DD') : ''}
        </p>
      </div> */}
    </div>
    </div>
  );
};

export default DateRangeSelector;
