import * as React from "react";
import {
  Form,
  // OverlayTrigger, Tooltip
} from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import _ from "lodash";
import { FiChevronDown } from "react-icons/fi";

export class DropdownMultiSelect extends React.Component<any, any> {
  private btnSection: any;
  private searchSection: any;
  constructor(props: any) {
    super(props);
    this.btnSection = React.createRef();
    this.searchSection = React.createRef();
    this.state = {
      selectedItems: [],
      filterData: [],
      opened: false,
      itemList: props.data,
      isSelectAll: props.isSelectAll,
      filterTitle: props.filtertitle,
      isSelect: true,
      inputValue: "",
      childClick: false,
    };
  }

  componentDidMount = () => {
    debugger;
    if(this.state.itemList!=undefined)
    {
    const activeChip = this.state.itemList.filter(
      (itemList: { isChecked: boolean }) => itemList.isChecked === true
    );
    this.setState({
      selectedItems: activeChip,
      filterData: this.state.itemList,
    });
  }
    //this.props.updateMultiSelectItem(activeChip);
  };

  componentDidUpdate = (prevProps) => {
    const { defaultValue } = this.props;
    if (prevProps?.defaultValue !== defaultValue) {
      let itemList = _.cloneDeep(this.props.data);
      if (!defaultValue) {
        itemList = itemList?.map((x) => {
          x.isChecked = false;
          return x;
        });
        this.handleClearAll();
      }

      const activeChip = itemList.filter(
        (itemList: { isChecked: boolean }) => itemList.isChecked === true
      );

      this.setState({
        selectedItems: activeChip,
        filterData: itemList,
      });
    }
  };

  onChipMouseOver = (): void => {
    this.setState({ childClick: true });
  };
  onChipMouseOut = (): void => {
    this.setState({ childClick: false });
  };

  /*For remove COA(s) list */
  handleRemoveSpecificChip = (idx: number) => () => {
    this.setState({ childClick: true });
    const { value } = this.props;
    const itemList = [...this.state.itemList];
    const selectedItems = [...this.state.selectedItems];
    const removeSelect = this.getIndex(selectedItems[idx][value], "removeChip");
    itemList[removeSelect].isChecked = false;
    selectedItems.splice(idx, 1);
    this.setState({ selectedItems, itemList });

    const coaSelectedItem = this.state.itemList.filter(
      (itemList: { isChecked: boolean }) => itemList.isChecked === true
    );
    this.props.updateMultiSelectItem(coaSelectedItem);
    this.setState({ childClick: false });
  };

  /*****checkbox check unchek***/
  handleCheckCheckbox = (idx: any) => (event: any) => {
    let { isSelect } = this.state;
    const { value } = this.props;
    const itemList = [...this.state.itemList];
    const index = itemList.findIndex((xvalue) => xvalue[value] === idx);
    itemList[index].isChecked = event.target.checked;
    const selectedItems = itemList.filter((r) => r.isChecked);
    isSelect = itemList.some((o: any) => !o.isChecked);
    this.setState({ selectedItems, itemList, isSelect });
    this.props.updateMultiSelectItem(selectedItems);
  };
  //Get array Index
  getIndex = (val: any, type: string) => {
    const { value } = this.props;
    if (type === "removeChip") {
      let itemList = [...this.state.itemList];
      return itemList.findIndex((obj) => obj[value] === val);
    } else {
      let selectedItems = [...this.state.selectedItems];
      return selectedItems.findIndex((obj) => obj[value] === val);
    }
  };
  handleChange = (e: { target: { value: string } }) => {
    const {
      // value,
      label,
    } = this.props;
    const updatedList = this.state.itemList.filter((item) => {
      return (
        item[label]
          ?.toString()
          ?.toLowerCase()
          ?.indexOf(e.target.value?.toLowerCase()) > -1
      );
    });

    this.setState({ filterData: updatedList, inputValue: e.target.value });
  };

  updateSavedItem = (data: any) => {
    const { value } = this.props;
    let allCoaList = [...this.state.itemList];
    allCoaList.forEach((element) => {
      element.isChecked = false;
    });
    this.setState({ itemList: allCoaList });
    data.forEach((element: { value: any }) => {
      this.state.itemList.filter(
        (itemList: { value: any }) => itemList[value] === element[value]
      )[0].isChecked = true;
    });
    this.setState({ selectedItems: data });
  };
  handleClick = () => {
    if (this.state?.childClick === true) {
      return false;
    }

    this.setState((prevState) => ({
      opened: !prevState.opened,
    }));

    this.setState({
      filterData: this.state.itemList,
    });
    this.setState({ inputValue: "" });
  };

  handleOutsideClick = (e) => {
    this.setState((prevState) => ({
      opened: false,
    }));
    this.setState({ inputValue: "" });
  };

  handleSelectAll = (e: any) => {
    let allItemList = [...this.state.itemList];
    allItemList.map((element) => {
      if (
        this.state.filterData.filter((x) => x.deptID === element.deptID)
          .length > 0
      ) {
        element.isChecked = true;
      }
      return element;
    });
    this.setState({ itemList: allItemList, isSelect: false, opened: false });
    this.setState({ selectedItems: allItemList?.filter((x) => x.isChecked) });
    this.props.updateMultiSelectItem(allItemList?.filter((x) => x.isChecked));
  };

  handleClearAll = () => {
    let allItemList = [...this.state.itemList];
    allItemList.forEach((element) => {
      element.isChecked = false;
    });
    this.setState({ itemList: allItemList, isSelect: true });
    this.setState({ selectedItems: [] });
    this.props.updateMultiSelectItem([]);
  };

  render() {
    const { label, value } = this.props;
    return (
      <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
        <div
          className={
            this.state.opened
              ? "multiSelectSearch aca-codes opened"
              : "multiSelectSearch aca-codes"
          }
        >
          <div
            className="fieldArea"
            onMouseOut={this.onChipMouseOut}
            onClick={this.handleClick}
          >
            {this.state.selectedItems.length === 0 ? (
              <span className="areaPlaceholder">
                {this.props?.placeHolder}
                    <FiChevronDown style={{ marginLeft: '70%' }}/>
              </span>
              
            ) : (
              <div className="chip" onMouseOut={this.onChipMouseOut}>
                {this.state.selectedItems.map((item: any, idx: any) => (
                  <div
                    onMouseOver={this.onChipMouseOver}
                    className="chipSelect"
                    id={idx}
                    key={idx}
                  >
                    <div className="chipVal">{item[label]}</div>
                    <button
                      id="btnremove"
                      type="button"
                      className="cross"
                      onMouseOver={this.onChipMouseOver}
                      onClick={this.handleRemoveSpecificChip(idx)}
                    >
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.79465 0.229492L3.96598 3.05749L1.13798 0.229492L0.195312 1.17216L3.02331 4.00016L0.195312 6.82816L1.13798 7.77083L3.96598 4.94282L6.79465 7.77083L7.73731 6.82816L4.90931 4.00016L7.73731 1.17216L6.79465 0.229492Z"
                          fill="#6A6E73"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            )}
            <div
              className="coaSearch"
              onMouseOver={this.onChipMouseOver}
              ref={(searchSection) => {
                this.searchSection = searchSection;
              }}
            >
              <div className="dropDownPanel">
                <div className="SearchInput">
                  <input
                    key={"text-Filter"}
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    placeholder={this.props?.filtertitle}
                    value={this.state.inputValue}
                  />
                </div>
                <ul>
                  {this.state.filterData.map((item: any, idx: any) => (
                    <li id={item[value]} key={item[value]}>
                      <Form.Check
                      // custom
                        type="checkbox"
                        key={"custom-s" + item[label]}
                        id={"custom-s" + item[label]}
                        label={
                          <EllipsisWithTooltip placement="bottom">
                            {item[label]}
                          </EllipsisWithTooltip>
                        }
                        checked={item.isChecked}
                        onChange={this.handleCheckCheckbox(item[value])}
                      />
                    </li>
                  ))}
                </ul>
                {this.state.isSelectAll &&
                  this.state.filterData?.length > 0 && (
                    <div className="footer">
                      {this.state.isSelect && (
                        <button
                          id="selectAll"
                          type="button"
                          className="btn wht-bg txt-green"
                          onClick={this.handleSelectAll}
                        >
                          Select All
                        </button>
                      )}
                      {!this.state.isSelect &&
                        this.state.filterData?.length > 0 && (
                          <button
                            id="ClearAll"
                            type="button"
                            className="btn wht-bg txt-green"
                            onClick={this.handleClearAll}
                          >
                            Clear All
                          </button>
                        )}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
}